// api/cartMonitor.ts
export const fetchCartData = async (merchantId: number, outletId: number) => {
    const apiUrl = `https://logs.retailetics.com/api/v1/cart-monitor?merchantId=${merchantId}&outletId=${outletId}`;
    
    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    
    const data = await response.json();
    
    if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch cart data');
    }
    
    return data;
};