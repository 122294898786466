// components/LoginForm.tsx
import React, { useState } from 'react';
import { Outlet } from '../models/outlet';
import logo from '../assets/ezycart_logo.svg'

interface LoginFormProps {
  onLogin: (selectedOutlet: string, employeePin: string) => void;
  outlets: Outlet[];
  isLoading: boolean;
  isLoggingIn: boolean;  // Loading state for the login butto
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin, outlets, isLoading, isLoggingIn }) => {
  const [selectedOutlet, setSelectedOutlet] = useState<string>(''); 
  const [employeePin, setEmployeePin] = useState<string>('');        
  const [error, setError] = useState<string>('');                   

  const handleLogin = () => {
    if (!selectedOutlet || !employeePin) {
      setError('Please select an outlet and enter Employee Code');
      return;
    }
    onLogin(selectedOutlet, employeePin);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white p-6">
      <img src={logo} alt="ezyCart Support" className="mb-10 w-full max-w-xs" />

      <div className="w-full max-w-xs">
        {/* Dropdown for Outlets */}
        <label htmlFor="outlet" className="block text-gray-700">
          Outlet
        </label>
        <select
          id="outlet"
          value={selectedOutlet}
          onChange={(e) => setSelectedOutlet(e.target.value)}
          className="block w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          disabled={isLoading}  // Disable the dropdown while loading outlets
        >
          {isLoading ? (
            <option>Loading outlets...</option>
          ) : (
            <>
              <option value="">Select Outlet</option>
              {outlets.map((outlet: Outlet) => (
                <option key={outlet.url} value={outlet.url}>
                  {outlet.name}
                </option>
              ))}
            </>
          )}
        </select>

        {/* Employee Code Input */}
        <label htmlFor="employeeCode" className="block text-gray-700 mt-4">
          Employee Code
        </label>
        <input
          type="number"
          id="employeeCode"
          value={employeePin}
          onChange={(e) => setEmployeePin(e.target.value)}
          className="block w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          placeholder="Enter Employee Code"
        />

        {/* Error message */}
        {error && <p className="text-red-500 mt-2">{error}</p>}

        {/* Login Button */}
        <button
          type="button"
          onClick={handleLogin}
          className="w-full bg-red-500 text-white py-2 mt-6 rounded-lg"
          disabled={isLoggingIn}  // Disable button when logging in
        >
          {isLoggingIn ? 'Logging in...' : 'Login'}  {/* Show loading text */}
        </button>
      </div>

      {/* Help Button */}
      <div className="mt-6">
        <button
          type="button"
          onClick={() => alert('Please contact our Support +60147120490')}
          className="text-gray-500 text-sm"
        >
          Help
        </button>
      </div>
    </div>
  );
};

export default LoginForm;