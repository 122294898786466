import { initializeApp } from 'firebase/app';
import { getRemoteConfig, fetchAndActivate, getValue } from 'firebase/remote-config';
import defaultOutlets from '../data/outlets.json'; // Fallback JSON data

const firebaseConfig = {
    apiKey: "AIzaSyDYsM5YFqM1ZtFpCl8YEvnOpoo3cMcyW88",
    authDomain: "ezysupport-5e836.firebaseapp.com",
    projectId: "ezysupport-5e836",
    storageBucket: "ezysupport-5e836.appspot.com",
    messagingSenderId: "729419986631",
    appId: "1:729419986631:web:63b9e478d2eef9c10170e2",
    measurementId: "G-54RRNCNLBT"
  };

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Initialize Remote Config
const remoteConfig = getRemoteConfig(app);

// Set the Remote Config settings
remoteConfig.settings = {
  fetchTimeoutMillis: 10000,  // Wait for 10 seconds for fetch
  minimumFetchIntervalMillis: 3600000,  // Fetch data once per hour
};

export const getOutletsFromFirebase = async () => {
  try {
    await fetchAndActivate(remoteConfig);
    const outletsValue = getValue(remoteConfig, 'outlet_list').asString();
    
    if (!outletsValue) {
      throw new Error("Empty or invalid data from Firebase Remote Config");
    }

    const outlets = JSON.parse(outletsValue);
    return outlets;
  } catch (error) {
    console.error('Failed to fetch from Firebase Remote Config. Using fallback data.', error);
    return defaultOutlets;  // Fallback to local JSON data
  }
};