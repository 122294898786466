// api/auth.ts
export const login = async (url: string, employeePin: string, merchantId: number, outletId: number) => {
    const apiUrl = `${url}/ezyCart/cs/login`;
  
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        employeePin,
        registrationToken: '',
        merchantId,
        outletId,
        deviceId: '',
      }),
    });
  
    const data = await response.json();
  
    if (!response.ok) {
      throw new Error(data.message || 'Login failed');
    }
  
    return data;
  };

