// pages/Login.tsx
import React, { useEffect, useState } from 'react';
import { encryptData, decryptData, removeDataFromLocalStorage } from '../utils/encryption';
import LoginForm from '../components/LoginForm';
import { getOutletsFromFirebase } from '../services/firebase';
import { login } from '../api/auth';
import { Outlet } from '../models/outlet';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [outlets, setOutlets] = useState<Outlet[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {

    const userData = localStorage.getItem('userData');
    const outletData = localStorage.getItem('selectedOutlet');

    if (userData && outletData) {
      try {
        // const decryptedUserData = decryptData(userData);
        // const decryptedOutletData = decryptData(outletData);
        navigate('/Home');
      
        
        // Now you can use the decrypted data in your app
      } catch (error) {
        removeDataFromLocalStorage('userData');
        removeDataFromLocalStorage('selectedOutlet');
        fetchOutletFromFirebase();
        console.error('Error decrypting data:', error);
      }
    } else {
      fetchOutletFromFirebase();
    }
  }, []);

  // Call Firebase RemoteConfig to fetch data
  const fetchOutletFromFirebase = async() => {
    const fetchOutlets = async () => {
      setIsLoading(true);
      const fetchedOutlets = await getOutletsFromFirebase();
      setOutlets(fetchedOutlets);
      setIsLoading(false);
    };
    fetchOutlets();
  }

  const handleLogin = async (selectedUrl: string, employeePin: string) => {
    setIsLoggingIn(true);

    const selectedOutlet = outlets.find(outlet => outlet.url === selectedUrl);
    if (!selectedOutlet) {
      alert('Invalid outlet selected');
      setIsLoggingIn(false);
      return;
    }

    const { merchantId, outletId, url } = selectedOutlet;

    try {
      const responseData = await login(url, employeePin, merchantId, outletId);
      
      // Encrypt and store the login response and selected outlet
      const encryptedUserData = encryptData(responseData);
      const encryptedOutletData = encryptData(selectedOutlet);

      localStorage.setItem('userData', encryptedUserData);
      localStorage.setItem('selectedOutlet', encryptedOutletData);

      // Navigate to the home screen
      navigate('/home');
    } catch (error) {
      alert(error);
    } finally {
      setIsLoggingIn(false);
    }
  };

  return <LoginForm onLogin={handleLogin} outlets={outlets} isLoading={isLoading} isLoggingIn={isLoggingIn} />;
};

export default Login;