import CryptoJS from 'crypto-js';
const SECRET_KEY = 'XXXXXXXX';  // Use key from RemoteConfig

// Function to encrypt data
export const encryptData = (data: any) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

// Function to decrypt data
export const decryptData = (cipherText: string) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};

// Function to remove encrypted data from localStorage
export const removeDataFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};