import React from 'react';
import { Cart } from '../models/cart';
import hardwareProblemIcon from '../assets/hardware_problem_icon.svg';
import paymentSuccessIcon from '../assets/payment_success_icon.svg';
import helpIcon from '../assets/help_icon.svg';
import cartActiveIcon from '../assets/cart_active_icon.svg';
import locationIcon from '../assets/location_icon.svg';
import priceIcon from '../assets/price_icon.svg';
import batteryChargingIcon from '../assets/battery_charging_icon.svg';
import batteryIcon from '../assets/battery_icon.svg';
import batteryChargedIcon from '../assets/battery_charged_icon.svg';
import batteryLowIcon from '../assets/battery_low_icon.svg';
import cartNormalIcon from '../assets/cart_normal_icon.svg';
import noPayExitPaymentIcon from '../assets/no_pay_exit_payment_icon.svg';
import nonHalalIcon from '../assets/non_halal_icon.svg';
import alcoholIcon from '../assets/alcohol_icon.svg';
import cartPaymentSuccessIcon from '../assets/cart_payment_success_icon.svg';
import cartHelpIcon from '../assets/cart_help_icon.svg';
import cartNoPayExitIcon from '../assets/cart_no_pay_exit_icon.svg';
import cartSuspiciousIcon from '../assets/cart_suspicious_icon.svg';
import paySuspiciousIcon from '../assets/pay_suspicious_icon.svg';
import cartHardwareIcon from '../assets/cart_strikeout_icon.svg';



interface CartGridProps {
  cart: Cart;
  index: number;
  section: 'active' | 'idle' | 'abandoned';
}

const CartGrid: React.FC<CartGridProps> = ({ cart, index, section }) => {
  const { CartStatus, CurrentLocation, PowerBankBattery, TrolleyNumber, Price, isPrepaidAdded, isRestrictedAdded } = cart;

  // Border and Background color based on section
  const getBorderColor = () => {
    switch (section) {
      case 'active':
        return '#3EAEFF'; // Blue for active carts
      case 'idle':
        return '#E08A00'; // Orange for idle carts
      case 'abandoned':
        return '#E1E1E1'; // Grey for abandoned carts
      default:
        return 'transparent';
    }
  };

  const getBackgroundColor = () => {
    switch (section) {
      case 'active':
        return 'rgba(62, 174, 255, 0.04)'; // Light blue for active carts
      case 'idle':
        return 'rgba(224, 138, 0, 0.04)'; // Light orange for idle carts
      case 'abandoned':
        return 'rgba(207, 207, 207, 1)'; // Light grey for abandoned carts
      default:
        return 'transparent';
    }
  };

  // Cart status and message logic
  const getCartStatusInfo = () => {
    switch (CartStatus) {
      case 'ACTIVE_HW_ISSUE':
      case 'IDLE_HW_ISSUE':
        return {
          icon: hardwareProblemIcon,
          text: 'Hardware Problem',
          textColor: '#ffcc00',
          cartIcon: cartHardwareIcon,
          blink: false,
        };
      case 'PAYMENT_SUCCESS':
        return {
          icon: paymentSuccessIcon,
          text: 'Payment Success',
          textColor: '#1F8B04',
          cartIcon: cartPaymentSuccessIcon,
          blink: false,
        };
      case 'ACTIVE':
        return {
          icon: cartPaymentSuccessIcon,
          text: 'All Good',
          textColor: '#28A745',
          cartIcon: cartActiveIcon,
          blink: false,
        };
      case 'IDLE' :
        return {
          icon: cartNormalIcon,
          text: 'Ready to use',
          textColor: '#2A134F',
          cartIcon: cartNormalIcon,
          blink: false,
          };
      case 'ACTIVE_HELP':
        return {
          icon: helpIcon,
          text: 'Asks for Help',
          textColor: '#FFAC33',
          cartIcon: cartHelpIcon,
          blink: false,
        };
      case 'ACTIVE_SUSPICIOUS_EXIT':
        return {
          icon: noPayExitPaymentIcon,
          text: 'Exit Without Payment',
          textColor: '#C00000',
          cartIcon: cartNoPayExitIcon,
          blink: true,
        };
      case 'ACTIVE_SUSPICIOUS':
        return {
          icon: paySuspiciousIcon,
          text: 'Suspicious Activity',
          textColor: '#E08A00',
          cartIcon: cartSuspiciousIcon,
          blink: false,
        };
      case 'ABANDON_CART':
        return {
          icon: cartNormalIcon,
          text: 'Not in use',
          textColor: '#4A4A4A',
          cartIcon: cartNormalIcon,
          blink: false,
        };
      default:
        return {
          icon: null,
          text: '',
          textColor: 'transparent',
          cartIcon: cartNormalIcon,
          blink: false,
        };
    }
  };

  const cartStatusInfo = getCartStatusInfo();

  // Battery status logic
  const getBatteryStatus = () => {
    if (parseInt(PowerBankBattery) <= 10 ) {
      return { icon: batteryLowIcon, text: `${PowerBankBattery} (Battery Low)`, isLow: true };
    }
    if (parseInt(PowerBankBattery) === 100) {
      return { icon: batteryChargedIcon, text: `${PowerBankBattery} (Full)`, isLow: false };
    } else if (cart.PowerBankChargingStatus === 'Charging') {
      return { icon: batteryChargingIcon, text: `${PowerBankBattery} (Charging)`, isLow: false };
    } else if (cart.PowerBankChargingStatus === 'Discharging') {
      return { icon: batteryIcon, text: `${PowerBankBattery} (Discharging)`, isLow: false };
    }
    return { icon: batteryIcon, text: `${PowerBankBattery}`, isLow: false };
  };

  const { icon: batteryStatusIcon, text: batteryText, isLow } = getBatteryStatus();

  return (
    <div className="cart-grid" style={{ border: `1px solid ${getBorderColor()}`, backgroundColor: getBackgroundColor(), padding: '24px' }}>
      <div className="grid-header">
        {/* Trolley Number with rounded view */}
        <div className="grid-header-number">
          {TrolleyNumber}
        </div>
         <div className="icon-row">
          {isPrepaidAdded && <img src={nonHalalIcon} alt="Non Halal" />}
          {isRestrictedAdded && <img src={alcoholIcon} alt="Alcohol" />}
        </div>
      </div>

      <div className="grid-content">
        {/* Battery Status */}
        <div className="grid-item">
          <img src={batteryStatusIcon} alt="Battery Icon" />
          <span style={{ color: isLow ? 'red' : 'black' }}>{batteryText}</span>
        </div>

        {/* Location */}
        <div className="grid-item">
          <img src={locationIcon} alt="Location Icon" />
          <span>{CurrentLocation}</span>
        </div>

         {/* Price */}
         <div className="grid-item">
          <img src={priceIcon} alt="Location Icon" />
          <span>{Price}</span>
        </div>

        {/* Price and icons */}
        <div className="grid-item">
        {cartStatusInfo.icon && (
          <>
            <img src={cartStatusInfo.icon} alt="Status Icon" />
            <span style={{ color: cartStatusInfo.textColor }} className={cartStatusInfo.blink ? 'blink' : ''} // Apply blink class conditionally
            > {cartStatusInfo.text} </span>
          </>
        )}
      </div>
      </div>
    </div>
  );
};

export default CartGrid;